const state = {
  company: null
}

const mutations = {
  setCompany (state, { id }) {
    state.company = id
  },
  unsetData (state) {
    state.company = null
  }
}

const actions = {
  setCompany ({ commit }, data) {
    commit('setCompany', data)
  },
  clearData ({ commit }) {
    commit('unsetData')
  }
}

const getters = {
  company: (state) => state.company
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
