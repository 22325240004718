import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/router/auth'

Vue.use(Router)

function checkSession (to, from, next) {
  if (Vue.prototype.$session.exists()) {
    auth.check()
      .then(res => {
        if (res) {
          next()
        } else {
          next('/login')
        }
      })
  }
  else {
    next('/login')
  }
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      component: () => import(
        '@/pages/errors/404.vue'
      )
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(`@/pages/Login.vue`),
      beforeEnter (to, from, next) {
        if (Vue.prototype.$session.exists()) {
          auth.check()
            .then(res => {
              if (res) {
                next('/home')
              } else {
                next()
              }
            })
        }
        else {
          next()
        }
      }
    },
    {
      path: '/reset/password/:id',
      name: 'Reset',
      component: () => import(`@/pages/Reset.vue`)
    },
    {
      path: '/aviso-de-privacidad',
      name: 'NoticePrivacy',
      component: () => import(`@/pages/NoticePrivacy.vue`)
    },
    {
      path: '/terminos-y-condiciones',
      name: 'Terms',
      component: () => import(`@/pages/Terms.vue`)
    },
    {
      path: '/',
      name: 'Root',
      redirect: {
        name: 'Home'
      },
      component: () => import(`@/pages/Root.vue`),
      children: [
        {
          path: '/home',
          name: 'Home',
          component: () => import(`@/pages/Home.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/perfil',
          name: 'Profile',
          component: () => import(`@/pages/Profile.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/semaforo',
          name: 'Semaphore',
          component: () => import(`@/pages/Semaphore.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/operadores',
          name: 'Driver',
          component: () => import(`@/pages/Driver.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/mapa',
          name: 'Map',
          component: () => import(`@/pages/Map.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/pruebas-de-simulacion',
          name: 'SimulationTest',
          component: () => import(`@/pages/SimulationTest.vue`),
          beforeEnter: checkSession
        },
        {
          path: '/prototipo',
          name: 'Prototype',
          component: () => import(`@/pages/Prototype.vue`),
          beforeEnter: checkSession
        }
      ]
    }
  ]
});

router.beforeEach((to, from, next) => {
  next();
});

router.afterEach((to, from) => {
});

export default router
