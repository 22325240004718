<template>
  <router-view />
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSession from 'vue-session'
import Vuetify from 'vuetify'
import VueTour from "vue-tour";
import * as VueGoogleMaps from "vue2-google-maps";

require('vue-tour/dist/vue-tour.css')

Vue.use(Vuetify)
Vue.use(VueAxios, axios)
Vue.use(VueSession)
Vue.use(VueTour)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: "places,geometry,drawing",
    channel:'shaker_suite'
  }
})

export default {
  name: 'App'
}
</script>
